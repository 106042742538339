<template>
  <v-card class="fill-height d-flex flex-column">
    <v-card-title>{{ foptype.name }}</v-card-title>
    <v-card-subtitle>
      <v-col>
        <v-row
          v-for="x in foptype.fields"
          :key="x._id"
          dense
        >
          <v-icon
            small
            class="pr-3"
          >
            {{ getFieldIcon(x.field_type) }}
          </v-icon>
          <span>
            {{ x.name }}
          </span>
        </v-row>
      </v-col>
    </v-card-subtitle>
    <v-spacer />
    <v-card-actions>
      <v-row
        justify="center"
        dense
      >
        <v-col cols="auto">
          <v-btn
            color="orange"
            dark
            class="ma-2 white--text"
            @click="$emit('edit')"
          >
            MODIFIER<v-icon color="white">
              mdi-pencil
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="red"
            dark
            class="ma-2 white--text"
            @click="$emit('delete')"
          >
            SUPPRIMER<v-icon color="white">
              mdi-delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import utils from "@/utils";

export default {
  props: ["foptype"],
  data: () => ({}),
  methods: {
    getFieldIcon: utils.getFopFieldIcon,
  },
};
</script>
