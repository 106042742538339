<template>
  <div>
    <v-card flat>
      <v-card-title
        class="mb-2 text-h4"
        style="word-break: keep-all"
      >
        Gestion des types de FOP
      </v-card-title>
      <v-card-subtitle>
        Cette page permet aux administrateurs de gérer les types de FOPs.
      </v-card-subtitle>

      <v-card-text v-if="!loading">
        <v-row align="center">
          <v-col cols="auto">
            <v-btn
              elevation="1"
              outlined
              rounded
              @click="modalCreate()"
            >
              Créer un type de FOP
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="ml-auto"
          >
            <v-text-field
              v-model="search"
              outlined
              rounded
              hide-details="auto"
              dense
              label="Recherche"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="x in getFopTypes"
            :key="x._id"
            md="4"
            sm="6"
            cols="12"
          >
            <FopTypeCard
              :foptype="x"
              @edit="modalEdit(x)"
              @delete="deleteFopType(x)"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-progress-circular
        v-else
        class="d-block mx-auto"
        indeterminate
      />
    </v-card>

    <FopTypeModal
      v-model="modal.foptype"
      :visible.sync="modal.visible"
      :goal="modal.goal"
      @done="modalDone()"
    />
  </div>
</template>

<script>
import utils from "@/utils";
import FopTypeCard from "@/components/Fop/FopTypeCard";
import FopTypeModal from "@/components/Fop/FopTypeModal";

export default {
  components: { FopTypeCard: FopTypeCard, FopTypeModal: FopTypeModal },
  data: function () {
    return {
      foptypes: [],
      modal: {},
      loading: true,
      search: "",
    };
  },
  computed: {
    getFopTypes() {
      if (!this.search) return this.foptypes;
      let reg = new RegExp(this.search, "i");
      return this.foptypes.filter((x) => x.name.match(reg));
    },
  },
  created() {
    this.loadFopTypes();
  },
  methods: {
    modalCreate() {
      this.modal = {
        goal: "create",
        foptype: {
          name: "",
          fields: [],
        },
        visible: true,
      };
    },
    modalEdit(foptype) {
      this.modal = {
        goal: "edit",
        foptype: foptype,
        visible: true,
      };
    },
    modalDone() {
      this.loadFopTypes();
    },
    loadFopTypes() {
      this.modal = {
        foptype: { name: "", fields: [] },
        goal: "",
        visible: false,
      };
      this.loading = true;
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/admin/getFOPTypes",
        {}
      )
        .then((res) => {
          this.loading = false;
          this.foptypes = res.data;
        })
        .catch((err) => {
          utils.handle(this, err);
        });
    },
    deleteFopType(foptype) {
      this.$alert.$emit("confirm", {
        title: "Supprimer " + foptype.name + " ?",
        message: "Supprimer le foptype ! Cette action est irréversible !",
        callback: () => {
          return this.axiosPost(
            process.env.VUE_APP_API_URL + "/admin/deleteFOPType",
            { ft_id: foptype._id }
          )
            .catch((err) => {
              utils.handle(this, err);
            })
            .then(() => {
              this.loadFopTypes();
            });
        },
      });
    },
  },
};
</script>
