<template>
  <v-dialog
    v-model="visibleValue"
    max-width="1100"
  >
    <v-card class="pa-3">
      <v-card-title>
        <v-chip
          color="teal"
          class="mr-4"
          label
          text-color="white"
          small
        >
          {{ goal == "create" ? "Créer" : "Modifier" }}
        </v-chip>
        <span>
          Type de FOP
        </span>
      </v-card-title>
      <v-card-text>
        <p v-if="errors.length">
          <b>Erreur{{ errors.length > 1 ? 's':'' }} :</b>
          <ul>
            <li
              v-for="(err,i) in errors"
              :key="i"
            >
              {{ err }}
            </li>
          </ul>
        </p>

        <v-stepper
          v-model="step"
          vertical
          non-linear
        >
          <v-stepper-step
            :complete="step>1"
            step="1"
            editable
          >
            Nom
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-text-field
              v-model="value.name"
              label="Nom du type de FOP"
            />
          </v-stepper-content>

          <v-stepper-step
            :complete="step>2"
            step="2"
            editable
          >
            Champs
          </v-stepper-step>
          <v-stepper-content step="2">
            <div style="display:flex; flex-direction: column; gap: 10px;">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Type</th>
                    <th>Requis</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(field,i) of value.fields"
                    :key="i"
                  >
                    <td>
                      <v-text-field
                        v-model="field.name"
                        placeholder="Nom du champ"
                      />
                    </td>
                    <td>
                      <v-btn-toggle
                        v-model="toggles[i]"
                        @change="updateFieldType(i)"
                      >
                        <v-tooltip
                          v-for="(type,j) in field_types"
                          :key="j"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on">
                              <v-icon>{{ getFieldIcon(type.value) }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ type.text }}</span>
                        </v-tooltip>
                      </v-btn-toggle>
                    </td>
                    <td><v-checkbox v-model="field.metadata.fieldrequired" /></td>
                    <td>
                      <div>
                        <v-btn
                          icon
                          @click="moveUpField(i)"
                        >
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <v-btn
                          icon
                          @click="moveDownField(i)"
                        >
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                      </div>
                    </td>
                    <td>
                      <v-btn @click="deleteField(i)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-btn
                block
                @click="newField()"
              >
                Ajouter un champ
              </v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-step
            :complete="step>3"
            step="3"
            editable
          >
            Étapes
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-select
              v-model="value.steps_template"
              :items="steps_templates"
            />
          </v-stepper-content>
        </v-stepper>
        <div
          class="d-flex"
          style="margin-top:20px"
        >
          <v-btn
            color="primary"
            class="mx-auto"
            @click="submit()"
          >
            Valider
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import utils from "@/utils";

export default {
  props: {
    visible: Boolean,
    value: {
      type: Object,
      required: true,
    },
    goal: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      field_types: [
        { text: "Texte court", value: "text" },
        { text: "Email", value: "email" },
        { text: "Texte long", value: "textarea" },
        { text: "Nombre", value: "number" },
        { text: "Date", value: "date" },
      ],
      step: 1,
      steps_templates: [],
      errors: [],
    };
  },
  computed: {
    visibleValue: {
      get() {
        return this.visible;
      },
      set(v) {
        this.$emit("update:visible", v);
      },
    },
    toggles() {
      let arr = Array(this.value.fields.length);
      for (let i = 0; i < this.value.fields.length; i++) {
        let field = this.value.fields[i];
        let type_index = this.field_types
          .map((x) => x.value)
          .indexOf(field.field_type);
        arr[i] = type_index >= 0 ? type_index : undefined;
      }
      return arr;
    },
  },
  created() {
    this.loadStepsTemplates();
  },
  methods: {
    getFieldIcon: utils.getFopFieldIcon,
    loadStepsTemplates() {
      this.axiosPost(process.env.VUE_APP_API_URL + "/fops/getFopStepsTemplates")
        .catch((err) => {
          utils.handle(this, err);
        })
        .then((res) => {
          this.steps_templates = res.data.map((x) => {
            return { text: x.name, value: x._id };
          });
        });
    },
    updateFieldType(index) {
      let field = this.value.fields[index];
      let toggle = this.toggles[index];
      field.field_type = this.field_types[toggle].value;
    },
    deleteField(i) {
      this.value.fields.splice(i,1);
    },
    moveUpField(i) {
      if(i < 1) return;
      this.value.fields.splice(i - 1, 2, this.value.fields[i], this.value.fields[i - 1]);
    },
    moveDownField(i) {
      if(i >= this.value.fields.length - 1) return;
      this.value.fields.splice(i, 2, this.value.fields[i + 1], this.value.fields[i]);
    },
    newField() {
      this.value.fields.push({
        name: "",
        field_type: undefined,
        metadata: {
          fieldrequired: false,
        },
        steps_template: undefined,
      });
    },
    submit() {
      this.errors = [];
      if (!this.value.name || this.value.name == "")
        this.errors.push("Le type de FOP n'a pas de nom.");
      this.value.fields.forEach((field, i) => {
        if (!field.name || this.value.name == "")
          this.errors.push(`Le champ n°${i + 1} n'a pas de nom.`);
        if (!field.field_type)
          this.errors.push(`Le champ n°${i + 1} n'a pas de type.`);
      });
      if (!this.value.steps_template)
        this.errors.push("Le type de FOP n'a pas d'étapes de validation.");

      if (this.errors.length > 0) return;
      if (this.goal == "create") this.createFopType(this.value);
      if (this.goal == "edit") this.editFopType(this.value);
    },
    editFopType(foptype) {
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/admin/updateFOPType",
        { foptype }
      )
        .catch((err) => {
          utils.handle(this, err);
        })
        .then(() => {
          this.$emit("done");
        });
    },
    createFopType(foptype) {
      return this.axiosPost(
        process.env.VUE_APP_API_URL + "/admin/createFOPType",
        { foptype }
      )
        .catch((err) => {
          utils.handle(this, err);
        })
        .then(() => {
          this.$emit("done");
        });
    },
  },
};
</script>